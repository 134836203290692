iframe {pointer-events: none;}
.dashnoard_data_area{ position: relative; }
/*.sliderParent{position: absolute; left: 70px; bottom:calc( var(--slider-height) - 317px); z-index: 0;}*/
.sliderParent{position: absolute; left: 60px; bottom: 20px; z-index: 1; height: 20px; /*background-color: #fc0;*/}
.sliderParent:hover{ z-index: 1;}
.slidecontainer { margin-left: 0px; padding-top: 0px; height: 20px}
.slider {
  -webkit-appearance: none;
  width:var(--slider-width);
  height: 25px;
  background: transparent;
  outline: none;
  width: calc(100vw - 216px);
  opacity: .7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  position: relative;
  left: 0px;
  height: 20px;
  box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15);
  -moz-box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15);
  -webkit-box-shadow:inset 0 12px 12px 0px rgba(0,0,0,.15);
  border-radius: 20px;
}

.slider:hover, .slider:active, .slider:focus {
  opacity: .9;
  box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15) !important;
  -moz-box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15) !important;
  -webkit-box-shadow:inset 0 12px 12px 0px rgba(0,0,0,.15) !important;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: calc(var(--slider-width)/12);
  height: calc( var(--slider-height) + 195px);
  /*background-color: rgba(63, 209, 190, .3);*/
  /*background-color: rgba(255,0,0, .07);
  border: 1px solid rgba(0,0,0, .5);*/
  cursor: pointer;
  position: relative;
  background-image: url('./assets/arrow.png'), url('./assets/rectangle.png');
  background-position: center bottom,  center top;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100% 150px;
  bottom: calc( var(--slider-height) + 97px);
}
.slider::-webkit-slider-thumb:after{ content: ''; width: calc(var(--slider-width)/12); height: 100px; background-color: rgba(255,0,0, .07);}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}
#process{ position: relative; z-index: 5 }

g  > path {
  cursor: pointer;
}


/* filter react-select- */
.css-1okebmr-indicatorSeparator
{
  background-color: #FFF!important;
}

.css-14el2xx-placeholder {
color: #0B1419 !important;
}

.btn-color{
  background-color: #3FD1BE !important;
  border: #3FD1BE !important;
  color: #fff;
}

.btn-slide.pre, .btn-slide.next{ position: absolute; top: 0px; width: 20px; height: 20px; line-height: 0px;     font-size: 18px; background-color: #f1f1f1; border: 1px solid #ddd; border-radius: 100%;}
.btn-slide.pre{ left: -25px;}
.btn-slide.next{ right: -25px;}
.btn-slide.pre:after, .btn-slide.next:after{position: absolute; top: 7px; left: 0;}
.btn-slide.pre:after{content: "←";}
.btn-slide.next:after{content: "→";}


/***********************/


.slidersmall {
  -webkit-appearance: none;
  width:var(--slider-width);
  height: 25px;
  background: transparent;
  outline: none;
  width: calc(100vw - 350px);
  opacity: .7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  position: relative;
  left: 85px;
  height: 20px;
  box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15);
  -moz-box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15);
  -webkit-box-shadow:inset 0 12px 12px 0px rgba(0,0,0,.15);
  border-radius: 20px;
}

.slidersmall:hover, .slidersmall:active, .slidersmall:focus {
  opacity: .9;
  box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15) !important;
  -moz-box-shadow: inset 0 12px 12px 0px rgba(0,0,0,.15) !important;
  -webkit-box-shadow:inset 0 12px 12px 0px rgba(0,0,0,.15) !important;
}

.slidersmall::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: calc(var(--slider-width)/12);
  height: calc( var(--slider-height) + 195px);
  /*background-color: rgba(63, 209, 190, .3);*/
  /*background-color: rgba(255,0,0, .07);
  border: 1px solid rgba(0,0,0, .5);*/
  cursor: pointer;
  position: relative;
  background-image: url('./assets/arrow.png'), url('./assets/rectangle.png');
  background-position: center bottom,  center top;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100% 60px;
  bottom: calc( var(--slider-height) - 20px);
}
.slidersmall::-webkit-slider-thumb:after{ content: ''; width: calc(var(--slider-width)/12); height: 100px; background-color: rgba(255,0,0, .07);}

.slidersmall::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}