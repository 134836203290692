* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Proxima Nova', sans-serif;
}

html,
body {
    width: 100%;
    height: 100%;
}

@import url('http://fonts.cdnfonts.com/css/proxima-nova-2');

:focus {
    outline: none !important;
    box-shadow: none !important;
}

.dashboard_bg {
    display: flex;
    align-items: center;
}

.left_side {
    width: 80px;
    height: 100vh;
    background: #034348 0% 0% no-repeat padding-box;
    box-shadow: 5px 0px 6px #55555545;
    opacity: 1;
    padding: 15px 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.brand_name {
    text-align: center;
    font: normal normal bold 11px/13px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: -0.01px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 8px;
}

.menus {}

.profile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile .profile_bg {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 1.5px 1.5px 1.5px 1.5px;
    background-color: white;
    cursor: pointer;
}

.profile .profile_bg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.right_side {
    width: 100%;
    min-height: 100vh;
    padding-top: 15px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: #F2F8F8;
    margin-left: 80px;
}

.breadcrumb {
    width: 100%;
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    display: flex;
    align-items: center !important;
    justify-content: space-between;
}

.breadcrumb .breadcrumb-item a {
    text-decoration: none;
    color: #788086 !important;
}

.breadcrumb .breadcrumb-item {
    font: normal normal bold 12px/14px Proxima Nova;
    letter-spacing: -0.4px;
    /* color: #383838; */
    color: #788086 !important;
    opacity: 1;
}

.breadcrumb .active a {
    color: #383838 !important;
}

.breadcrumb .home_icon a {
    color: #034348 !important;
}

.toggle_bar {
    display: flex;
    align-items: center;
}

.toggle_bar .toggle_btn {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #F2F8F8 0% 0% no-repeat padding-box;
    border: none;
}

.toggle_bar .toggle_btn1 {
    /* background: #F2F8F8 0% 0% no-repeat padding-box; */
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
}

.toggle_bar .toggle_btn1 i {
    color: #3FD1BE;
}

.toggle_bar .toggle_btn2 {
    /* background: #3FD1BE 0% 0% no-repeat padding-box; */
    border-radius: 0px 5px 5px 0px;
    opacity: 1;
}

.toggle_bar .toggle_btn2 i {
    color: white;
}

.toggle_bar .active {
    background: #3FD1BE 0% 0% no-repeat padding-box;
}

.dashboard_section {
    padding: 20px 15px;
    position: relative;
}

.sort_and_filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sort_and_filter .left {
    width: fit-content;
    padding: 0;
    background: #6FDED033 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.sort_and_filter .left .select {
    width: 173px;
    height: 36px;
    background-color: transparent;
    border: none;
    font: normal normal bold 13px/16px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.46px;
    color: #00A696;
}

.sort_and_filter .left .select:focus {
    outline: none;
    box-shadow: none;
}

.sort_and_filter .right {
    display: flex;
    align-items: center;
}

.sort_and_filter .right .indicators {
    /* width: 144px; */
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 0 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.sort_and_filter .right .indicators.active {
    background: #ddeeef
}

.sort_and_filter .right .indicat_colors {
    width: 14px;
    height: 14px;
    border-radius: 2px;
}

.color_code_one {
    background: #FFC034 0% 0% no-repeat padding-box;
}

.color_code_two {
    background: #3A9743 0% 0% no-repeat padding-box;
}

.color_code_three {
    background: #000000 0% 0% no-repeat padding-box;
}

.sort_and_filter .right .indicators .indicators_name {
    font: normal normal 600 12px/15px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #0B1419;
}

.sort_and_filter .right .indicators .filter_icon {
    font-size: 20px;
    color: #3FD1BE !important;
}

.sort_and_filter .right .filter_indicator {
    cursor: pointer;
}

.dashboard_section .dashboard_data_area {
    width: 100%;
    min-height: 500px;
    height: auto !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 16px #000F211A;
    border: 1px solid #EDEFEF;
    border-radius: 6px;
    position: relative;
}

.dashboard_section .dashboard_data_area1{

    min-height: initial!important ;

}
/* Modal Related CSS */

.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 320px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal-header {
    border-bottom: none !important;
}

.light_line {
    height: 2px !important;
    color: #a1a1a1 !important;
    box-shadow: 0 1 0 rgba(0, 0, 0, 0.212);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border: none !important;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px 80px;
}

/*Right*/

.modal.right.fade .modal-dialog {
    right: 0;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
    right: 0;
}

.filter_modal_heading {
    font: normal normal bold 20px/24px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: -0.02px;
    color: #0D1324;
}

.filter_modal_heading i {
    color: #3FD1BE;
}

.right .modal-content {
    border-radius: 0 !important;
}

.checkbox_sz {
    width: 18px !important;
    height: 18px !important;
}

/* .right_side_modal{
    overflow-y: scroll;
}

.right_side_modal::-webkit-scrollbar{
    overflow: hidden;
} */

/* Modal Related CSS */

.year_select {
    width: 80px;
    height: 35px;
    background: #F7FAFD 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.year_select:focus {
    outline: none;
    border: none;
}

.detail_box {
    position: absolute;
    /*width: 573px;*/
    /* height: 215px; */
    background: #F0F6F7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 20px;
    z-index: 10;
}

.detail_box .logo_box {
    width: 40px;
    height: 40px;
    background: #034348 0% 0% no-repeat padding-box;
    border-radius: 5px;
}

.detail_box .bold_text {
    font: normal normal bold 16px/22px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.56px;
    color: #151515;
}

.detail_box .light_text {
    font: normal normal normal 11px/14px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.39px;
    color: #151515;
}

.row_two .section_one,
.row_two .section_two {
    border-right: 2px solid #70707054;
}

.inside_circle i {
    color: white;
}

.row_two .graph_icon_section .inside_circle {
    width: 33px;
    height: 33px;

    /*background: #FFC034 0% 0% no-repeat padding-box;*/
    border-radius: 17px;
}

/* .row_two .graph_icon_section:hover{
  cursor: pointer;   
} */

.graph_icon_section {
    border-right: 3px solid #dee3e4
}

.graph_icon_section:last-child {
    border-right: none;
    padding-right: 0 !important
}

.graph_icon_section:first-child {
    padding-left: 0 !important
}

.bold_text_two {
    font: normal normal bold 13px/17px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.46px;
    color: #151515;
}

.light_text_two {
    font: normal normal normal 11px/12px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.32px;
    color: #151515;
}

.inside_link {
    font: normal normal bold 14px/10px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif !important;
    /* font-size: 15px; */
    font-weight: 600 !important;
    letter-spacing: 0.28px;
    color: #0082FA;
    cursor: pointer;
    z-index: 999 !important;
}

.modal_field_header {
    font: normal normal bold 14px/17px Proxima Nova;
    letter-spacing: 0px;
    color: #0D1324;
}

.radio_links {
    font: normal normal normal 13px/17px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #0B1419;
    margin-left: 10px;
    padding-top: 5px;
}

.rmsc .dropdown-container {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.modal_inputs {
    /* width: 255px; */
    width: 100%;
    height: 40px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 1px solid #EFEFEF !important;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 5px;

    font: normal normal normal 12px/15px Proxima Nova;
    letter-spacing: 0px;
    color: #0B1419;
}

.modal_inputs:focus {
    outline: none !important;
    box-shadow: none !important;
}

.rmsc .gray {
    color: #0B1419 !important;
}

.rmsc .dropdown-container:focus-within {
    border-color: #EFEFEF !important;
}

.modal_inputs::placeholder,
.modal_inputs option {
    font: normal normal normal 12px/15px Proxima Nova;
    letter-spacing: 0px;
    color: #0B1419;
}

.right_side_modal .form-check-input:checked {
    background-color: #3FD1BE !important;
    border-color: #3FD1BE !important;
}

.modal-90w {
    min-width: 95% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-90w .modal_xl_header {
    font: normal normal bold 22px/27px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: -0.02px;
    color: #0D1324;
}

.modal-90w .modal_xl_header .bullet_point {
    width: 11px !important;
    height: 11px !important;
    border-radius: 50% !important;
    background-color: #3FD1BE !important;
}

.modal-90w .text_area {
    width: 500px !important;
}

.modal-90w .modal-body {
    /* height: 82vh !important; */
    background: #F0F6F7;
    border-radius: 0 0 .5rem .5rem !important;
    /* overflow: scroll; */
}

.modal-90w .modal-body::-webkit-scrollbar {
    overflow-y: hidden !important;
}

.modal-90w .xl_inside_heading {
    font: normal normal bold 14px/17px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #0D1324;
}

.modal-90w .table_head {
    border-bottom: none !important;
    font: normal normal bold 12px/15px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #0D1324;
}

/* .modal-90w .tb_head_one {
    width: 5% !important;
} */

/* .modal-90w .tb_data_start{
    width: 3% !important;
} */

.modal-90w .tb_head_one {
    width: 3% !important;
    /* width: 50px !important; */
}

.modal-90w .tb_head_one .tb_head_one_inner{
    /* width: 80%; */
    width: 30px;
}

.modal-90w .tb_head_two {
    /* width: 25% !important; */
    width: calc(26% - 0px) !important;
}
.modal-90w td.tb_head_two {
    padding-right: .5rem !important;
}

.modal-90w .tb_head_three {
    width: 15% !important;
}

.modal-90w .tb_head_four {
    width: 9% !important;
}

.modal-90w .tb_data {
    border-bottom: none !important;
}

.modal-90w .tb_data img {
    width: 23px;
    fill: red !important;
}

.modal-90w .tb_data {
    font: normal normal medium 12px/15px Proxima Nova;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #38415A;
    font-size: 13px;
}

.modal-90w .table_row {
    background-color: white !important;
    box-shadow: 0px 6px 12px #2C3F4314;
    border-radius: 4px !important;
}

.modal-90w .tb_data_start {
    border-radius: 4px 0 0 4px !important;
}

.tb_data_end {
    border-radius: 0 4px 4px 0 !important;
}

.modal-90w .tbl_mrg_btm {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.graph-indicator {
    position: absolute;
    left: 10px;
    top: 0;
    /*top: 14px;
    transform: translateY(-50%);*/
    width: 35px;
    height: 51px;
    display: flex;
    border: 1px solid #ddd;
    background: #fff !important;
}

.graph-indicator_bg {
    width: 10px;
    height: 10px;
    margin: auto;
    align-items: center;
}

.graph_area {
    position: relative;
    left: 30px;
    height: 50px !important;
    width: calc(100% - 40px) !important;
}
.graph_area_lineChart .graph_area {
    height: auto !important;
    margin-bottom: -45px;
    left: 0;
    width: calc(100% - 40px) !important;
}

.graph_area.position-relative .graph_area>div {
    position: relative;
    top: -14px;
}

.areachart {
    padding-bottom: 30px;
}

.menus ul {
    margin: 0;
    padding: 0
}

.menus ul li a {
    padding: 15px 5px;
    color: #fff;
    font-size: 10px;
    display: block;
    text-align: center;
    text-decoration: none;
    border-left: 5px solid transparent
}

.menus ul li a span {
    display: inline-block;
    width: 100%
}

.menus ul li a i {
    font-size: 28px
}

.menus ul li.active a,
.menus ul li a:hover {
    background: rgba(255, 255, 255, .1);
    border-left: 5px solid #40fde4
}

.menus .icon_clr {
    fill: white !important;
}

/*.areachart .position-relative:last-child{ padding-top: 15px; }
.areachart .position-relative:last-child #document3{ position: relative; }
.areachart .position-relative:last-child #document3:before{ content: ''; position: absolute; z-index: 1; left: 15px; top: 14px; height: 1px; width: calc(100% - 30px); background: #fff; display: inline-block; }*/
.areachart .position-relative #document3 {
    height: 112px !important;
}

/*html{--slider-height: auto !important}*/
.right_side_modal .form-select {
    background-size: 16px auto !important;
    background-position: right 14px center !important;
}

.css-1s2u09g-control {
    border: 0px solid #EFEFEF !important;
    box-shadow: none !important;
}

.css-tlfecz-indicatorContainer,
.css-tlfecz-indicatorContainer:hover {
    color: hsl(0, 0%, 1%) !important;
}

.dropdown-heading svg,
.css-tj5bde-Svg {
    transform: scale(0.8) !important;
}

.dropdown-heading svg {
    position: relative;
    left: 4px;
}

/*.rmsc .dropdown-container[aria-expanded="false"] svg{ transform:scale(0.8)  rotate(90deg) !important; }*/
.dropdown-container .dropdown-content {
    max-height: 200px !important;
    overflow: auto;
}

.dashboard_section {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.modal-header .form-check {
    min-width: 105px;
}

.modal-header .form-check-input {
    width: 18px;
    height: 18px;
    border: 4px solid #FFF !important;
    box-shadow: 0 0 0 1px #D9D9D9 !important;
    vertical-align: middle;
    margin-right: 10px;
}

.modal-header .form-check-input:checked {
    background-color: #1ADEC5 !important;
    box-shadow: 0 0 0 1px #1ADEC5 !important;
}

.modal-header .form-check-input:checked[type=radio] {
    background-image: none !important;
}

.modal-header .form-check label {
    vertical-align: middle;
    position: relative;
    top: 0px;
    font-weight: 600;
    font-size: 13px;
}

.modal-header .form-check:first-child {
    margin-right: 20px;
}

.table thead th {
    font-size: 13px !important;
}

.table tbody td {
    vertical-align: middle;
}

.detail_box {
    width: 35%;
    max-width: 750px;
    min-width: 550px;
}

.detail_box .row_two .graph_icon_section {
    border-right: none !important;
}

.detail_box .row_two>.line {
    position: relative;
    height: 70px;
    width: 2px;
    background-color: #dee3e4;
}

.detail_box .row_two>.line:last-child {
    display: none !important;
}

.btn-close {
    background: url('close.png') center no-repeat !important;
    background-size: 16px !important;
    opacity: 1;
}

.table>:not(caption)>*>* {
    padding: .75rem .5rem;
}

/* .modal-90w .tb_data_start,
.modal-90w .tb_head_one {
    // max-width: 30px !important;
    max-width: 3% !important;
} */

nav[aria-label="breadcrumb"] ol {
    margin-bottom: 0
}

.modal.fade .modal-dialog {
    top: 100% !important;
    transform: translate(0, 100%) !important;
}

.selectfld select {
    background-image: url('../angle_down.png') !important;
    background-repeat: no-repeat;
    background-position: 92% center !important;
    background-size: 16px 14px !important;
}

.modal.show .modal-dialog {
    /*top: 50% !important;
    transform: translate(0,calc( -50% - 25px)) !important;*/
    top: 0 !important;
    transform: translate(0, 0%) !important;
}

select {
    -webkit-appearance: none !important;
    appearance: none !important;
    -moz-appearance: none !important;
}

/* Safari 6.1-10.0 (not 10.1) */
/*@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { @media {
body { background-color:#f90 !important;}
    select{-webkit-appearance: none !important;}
}}*/


/* Safari 10.1 */
/*@media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
    body { background-color:#fc0 !important;}
    select{-webkit-appearance: none !important;}
}}*/

/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm) {
    @media {

        /*body { background-color:#f00 !important;}*/
        select {
            -webkit-appearance: none !important;
        }

        .selectfld {
            position: relative;
            display: inline-block;
        }

        /*.selectfld:after{ content: ''; position: absolute; right: 8px; top: 12px; width: 8px; height: 8px; border-top: 2px solid #000; border-right: 2px solid #000;transform: rotate(134deg);}*/
        .selectfld select {
            background-image: url('../angle_down.png') !important;
            background-repeat: no-repeat;
            background-position: 92% center !important;
            background-size: 17px 14px !important;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .detail_box {
        min-width: 450px;
        padding: 15px !important;
    }

    .graph_icon_section.px-4 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .graph_icon_section:first-child {
        padding-left: 0 !important;
    }

    .graph_icon_section:last-child {
        padding-right: 0 !important;
    }
}

@media only screen and (max-width: 768px) {
    .modal-90w .tb_head_one {
        width: 5% !important;
    }
    
    .modal-90w .tb_head_two {
        width: 30% !important;
    }
    
    .modal-90w .tb_head_three {
        width: 20% !important;
    }
    
    .modal-90w .tb_head_four {
        width: 15% !important;
    } 

    .tb_head_three_sm{
        display: none !important;
        background-color: #0082FA !important;
    }
}

.tree_loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.tree_loader .spinner-border {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.textflow_hdn .cnt {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*.textflow_hdn{ position: relative; overflow: hidden; }
.textflow_hdn .textflow_visible{ position: absolute; left: 0rem; top: 0rem; padding: .75rem .5rem; height: calc(100% - 0rem); display: flex;  align-items: center; background: #fff;}
.textflow_hdn:hover{ overflow: visible; }
.textflow_hdn:hover .textflow_visible{ box-shadow: 0 0 40px 0 rgb(12 48 71 / 28%); -webkit-transform: scale(1.5); transform: scale(1.5); -webkit-transform-origin: top left; transform-origin: top left; font-size: .55rem; z-index: 9999; min-height: calc(100% - 1rem); height: auto; padding: .65rem .35rem .5rem ;}
.textflow_hdn:hover .textflow_visible p{height: auto; overflow: visible;}*/

.loader_sp {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .8);
}

.loader_sp .spinner {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/*.scrollbody{ height: 400px; overflow: auto; }*/
.scrollbody tbody,
.scrollbody thead {
    display: block !important;
}

.scrollbody thead tr {
    width: 100%;
    display: table;
}

/*.scrollbody tbody tr{ width: 100%; display: block;}*/
.scrollbody tbody {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbody tbody tr {
    display: table-row !important;
}

.lineChartAreaParent{
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
    overflow-x: hidden !important;
    /* overflow-y: scroll !important; */
}

.lineChartAreaParentOverFlowHidden{
    overflow-y: hidden !important;
}

.lineChartAreaParentOverFlow{
    overflow-y: scroll !important;
}

.lineChartAreaParentOverFlow::-webkit-scrollbar{
    width: 6px !important;
    cursor: pointer !important;
}

.lineChartAreaParentOverFlow::-webkit-scrollbar-thumb{
    border-radius: 5px;
}

.lineChartAreaParent::-webkit-scrollbar{
    overflow: hidden !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.linechartArea {
    /*height:calc(100vh - 200px);*/
    /* width: 75px; */
    width: 40px;
    margin: 5px auto;
    position: relative;
    transform: scale(1);
}

/*.zoomOne { transform: scale(var(--scale_value));}
.zoomTwo { transform: scale(2);}
.zoomThree { transform: scale(3);}
.zoomFour { transform: scale(4);}
.zoomSix { transform: scale(5);}
.zoomSeven { transform: scale(6);} 
*/

.linechartArea.zoomOne {
    /* width: 60px; */
    width: 45px;
}
.linechartArea.zoomTwo { width: 80px; }
.linechartArea.zoomThree{ width: 100px; }
.linechartArea.zoomFour {width: 120px;}
.linechartArea.zoomFive {width: 140px; }
.linechartArea.zoomSix{ width: 160px; }
.linechartArea.zoomSeven{ width: 180px; }

.linechartArea:after,
.linechartArea:before {
    /* content: '';
    position: absolute;
    width: 76px;
    height: 50px;
    left: 50%;
    margin-left: -38px;
    z-index: 1; */

    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.linechartArea:before {
    top: 0;
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 240, 0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 240, 0) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 240, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#fffff0", GradientType=1);
}

.linechartArea:after {
    bottom: 0;
    background: rgb(255, 255, 240);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 240, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 240, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(180deg, rgba(255, 255, 240, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffff0", endColorstr="#ffffff", GradientType=1);
}

.monthActivity {
    position: relative;
}

.monthActivityMargBtm{
    margin-bottom: 20px;
}

.monthActivityList {
    margin: 0;
    padding: 0;
    display: flex;
    /* flex-direction: row-reverse; */
    flex-wrap: wrap;
    list-style: none;
}
.monthActivityList li{
    /* width: 8px;    */
    width: 9px;   
}

.zoomone .monthActivityList li, .zoomTwo .monthActivityList li, .zoomThree .monthActivityList li, .zoomFour .monthActivityList li, .zoomFive .monthActivityList li, .zoomSix .monthActivityList li, .zoomSeven .monthActivityList li {
    /* width: 20%; */
    /*width: 8px;*/
     width: 17% !important; 
}

.monthActivityList li a {
    display: block;
    background: #f1f1f1;
    /* border-radius: 4px; */
    border-radius: 2px;
    margin: 1px;
}

.monthActivityList li a.email {
    background: rgb(58, 151, 67);
}

.monthActivityList li a.document {
    background: rgb(255, 192, 52);
}

.monthActivityList li a.misc {
    background: rgb(0, 0, 0);
}

.monthActivityList li a.card_cube {
    background: #FFC034
}

.monthActivityList li a:after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.monthActivity_month {
    position: absolute;
    width: 80px;
    height: 100%;
    min-height: 50px;
    min-height: fit-content !important;
    padding: 0 10px;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}

.blankMonthActivity_month{
    height: 5px !important;
}

/*.monthActivity:nth-child(even) .monthActivity_month {
    left: 110%;
    border-left: 3px solid #000;
    margin-left: 10px;
}

.monthActivity:nth-child(even) .monthActivity_month_strip {
   display: none !important;
}

.monthActivity:nth-child(odd) .monthActivity_month_strip {
    display: none !important;
}

.monthActivity:nth-child(odd) .monthActivity_month {
    right: 110%;
    border-right: 3px solid #000;
    margin-right: 10px;
}*/

.monthActivity.leftLine .monthActivity_month {
    left: 110%;
    border-left: 3px solid #000;
    margin-left: 10px;
}

.monthActivity.leftLine .monthActivity_month_strip {
   display: none !important;
}

.monthActivity.rightLine .monthActivity_month_strip {
    display: none !important;
}

.monthActivity.rightLine .monthActivity_month {
    right: 110%;
    border-right: 3px solid #000;
    margin-right: 10px;
}

.monthActivity_month h6 {
    /* font-size: 1.2rem; */
    font-size: .7rem;
    font-weight: 800;
    margin-bottom: 5px;
    line-height: 100%;
}

.monthActivity_month .activityNum {
    /* font-size: .75rem; */
    font-size: .7rem;
    font-weight: 600;
    line-height: 100%;
}

.yearStatusParent{
    height: 70vh !important;
}

.yearStatus{
    height: 100% !important;
    width: 80px;
    /* padding-left: 14px !important;
    padding-right: 14px !important; */
    /* padding-top: 10px !important;
    padding-bottom: 10px !important; */
}

.yearStatusInner{
    width: 100%;
    /* height: 100%; */
    height: 84%;
    overflow: hidden !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    /* padding-left: 14px !important;
    padding-right: 14px !important; */
}

.yearStatusInner::-webkit-scrollbar{
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    width: 0px !important;
}

.thisYearText{
    font-size: 12px !important;
    font-weight: 700 !important;

    padding-left: 25px !important;
    padding-right: 25px !important;
}

.thisMonthText{
    font-size: 10px !important;
    cursor: pointer !important;

    /* padding-left: 25px !important;
    padding-right: 25px !important;
    margin-bottom: 2px !important; */

    padding-left: 10px !important;
    /* padding-right: 25px !important; */
    /* margin-bottom: 2px !important; */
    margin-left: 15px;
    margin-right: 15px;
}
.thisMonthTextActive{
    /* color: #00A696; */
    /* background-color: #3DBBAE; */
    color: #3DBBAE;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-left: 1px solid #3DBBAE;
    border-right: 1px solid #3DBBAE;
}

.thisMonthText:hover{
    color: #00A696 !important;
}

.tools{
    position: absolute;
    left: 0 !important;
    bottom: 0 !important;
    z-index: 9999 !important;
}

.navigation_btns{
    background-color: #00a695c2 !important;
    /* background-color: #d3d3d3c2 !important; */
    color: white !important;
    border: none !important;
}

.navigation_btns_dsbl{
    /* background-color: #00a695 !important; */
    background-color: #c5c5c5 !important;
}

.iRRwgs {
    /* height: 99vh !important; */
    height: 70vh !important;
}

.toDoBoxParent{
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: scroll;
}

.toDoBoxParent::-webkit-scrollbar{
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 0px !important;
}

.toDoBoxParent .toDoBox:nth-child(1){
    border: 1px solid #00a695 !important;
}

.toDoBox{
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.171);
}

.toDoBoxLevelSix{
    /* width: 154px !important; */
    width: 18% !important;
    /* height: 120px !important; */
    height: 137px !important;
}

.toDoBoxLevelSeven{
    /* width: 170px !important; */
    width: 18% !important;
    min-height: 225px !important;
}

.toDoBoxShadow{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.404) !important;
}

.toDoBoxLevelBoxShadow{
    box-shadow: none !important;
}

.toDoBoxLevelBoxShadow .toDoBoxLevelBoxIcon img{
    width: 75% !important;
}

.toDoBoxLevelSix .icon_box{
    width: 22px !important;
    height: 22px !important;
    border-radius: 2px;
    background-color: goldenrod !important;
}

.toDoBoxLevelSeven .icon_box{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: goldenrod !important;
}

.onZoomBox .onZoomBox_two_inner .icon_box{
    width: 10% !important;
    height: 10% !important;
    border-radius: 1px;
}

.toDoBox .icon_box img{
    width: 100%;
    height: auto;
}

.toDoBoxLevelSix .date_heading{
    font: normal normal bold 10px/17px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 10px !important;
    letter-spacing: 0.46px;
    color: #151515;
}

.toDoBoxLevelSeven .date_heading{
    font: normal normal bold 13px/17px Mecellem03-08-22;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.46px;
    color: #151515;
}

.toDoBoxLevelSix .userName{
    font: normal normal bold 11px/13px Proxima Nova;
    font-size: 10px !important;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.39px;
    color: #151515;
}

.toDoBoxLevelSeven .userName{
    white-space: nowrap !important; 
    width: 100% !important;
    font: normal normal bold 11px/13px Proxima Nova;
    font-size: 12px !important;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.39px;
    color: #151515;
}

.toDoBoxLevelSix .userComments{
    white-space: nowrap !important; 
    width: 90% !important;
    /* font: normal normal medium 18px/13px Proxima Nova; */
    font-size: 10px !important;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #151515;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.toDoBoxLevelSeven .userComments{
    white-space: nowrap !important; 
    width: 90% !important;
    /* font: normal normal medium 18px/13px Proxima Nova; */
    font-size: 12px !important;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0px;
    color: #151515;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.text_test{
    z-index: 99999 !important;
}

.toDoBox .orange_box{
    width: 35px;
    height: 35px;
    border-radius: 4px;
    /* background: #ffbf349c 0% 0% no-repeat padding-box !important; */
    background: #ffbf3460 0% 0% no-repeat padding-box;
    /* opacity: 0.35; */
}

.toDoBox .green_box{
    width: 35px;
    height: 35px;
    border-radius: 4px;
    background: #3a974360 0% 0% no-repeat padding-box;
    /* opacity: 0.2; */
}

.toDoBox .gray_box{
    width: 35px;
    height: 35px;
    border-radius: 4px;
    background: #6b6d7760 0% 0% no-repeat padding-box;
    /* opacity: 0.2; */
}

.toDoBox .orange_box img, .toDoBox .green_box img, .toDoBox .gray_box img{
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 1 !important; */
}

.toDoBoxLevelSix .toolBox_users_convo{
    height: 50px !important;
    overflow-y: scroll;
    /* background-color: yellowgreen; */
}

.toDoBoxLevelSeven .toolBox_users_convo{
    height: 140px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* background-color: yellowgreen; */
}

/* .transform-component-module_content__2jYgh{
    transform: translate3d(15.92px, 1px, 0px) !important;
} */

.transform-component-module_wrapper__1_Fgj {
    overflow: hidden !important;
    overflow-y: scroll !important;
}

.transform-component-module_content__2jYgh .container{
    overflow: hidden !important;
    overflow-y: scroll !important;
}

.transform-component-module_wrapper__1_Fgj::-webkit-scrollbar{
    width: 0 !important;
}

.transform-component-module_content__2jYgh {
  /* transform:var(--pan-zoom)!important; */
  /* background-color: var(--color); */
}

.transform-component-module_content__2jYgh .container::-webkit-scrollbar{
    width: 0 !important;
}

.onZoomBox{
    /* width: auto !important;
    height: auto !important; */
    border-radius: .5px;
    background-color: white;
    box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.171);
    padding: 1px !important;
    margin: 1px !important;
}

.onZoomBox_one{
    width: 12px !important;
    min-height: 8px !important;
}

.onZoomBox_two{
    width: 12px !important;
    min-height: 10px !important;
}

.onZoomBox .sm_text{
    font-size: 1px !important;
    line-height: 2px !important;
    /* line-height: normal !important; */
    /* margin-top: 1px !important; */
    /* margin-bottom: .5px !important; */
}

/* .onZoomBox .sm_text:nth-child(1){
    margin-top: 0px !important;
}
.onZoomBox .sm_text:nth-child(4){
    margin-bottom: 0px !important;
} */

.material-symbols-outlined{
    font-size: 16px !important;
}

.sankey_select:focus{
    box-shadow: none !important;
    outline: none !important;
}

.rs-picker-toggle{
    height: 38px !important;
    border-color: transparent !important;
    border: 1px solid #ced4da !important;
}

.rs-picker-toolbar-option:not(:last-child):before {
    background-color: transparent;
}

.rs-picker-toolbar-ranges {
   
    position: absolute;
    left: 9px;
    top: 31px;
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 110px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 6px 0;
}

.rs-picker-toolbar-ranges a {
    padding: 9px 7px;
    width: 100%;
    text-align: left;
    color: #000;
}

.rs-picker-toolbar-ranges a.rs-picker-toolbar-option:hover {
    background: rgba(111,222,208,.2) 0 0 no-repeat padding-box;
    color: #00a696;
    text-decoration: none;
}

.rs-picker-daterange-calendar-group{
    padding-left: 103px;
}

.rs-picker-daterange-menu .rs-picker-toolbar {
    max-width: 100%;
}

.monthDot{
    position: absolute;
    top: -13px;
    right: -18px;
}

.mrg_tp{
    margin-top: 10px !important;
}

.monthDot{
    width: 3px;
    height: 5px;
    background: black;
}

.monthActivity.leftLine .monthActivity_month .blankMonthActivity_month{
    /*left: auto !important;
    right: -28px !important;*/
}

.monthActivity.rightLine .monthActivity_month .blankMonthActivity_month{
    /*left: -27px !important;
    right: auto !important;*/
}

.opacity_y{
    opacity: 1 !important;
}

.change_position{
    opacity: 0 !important;
}

.fade {
    pointer-events: all !important;
    z-index: 99999 !important;
}

.modal-backdrop{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.499) 0%, rgba(199, 199, 199, 0.496) 50%, rgba(182, 182, 182, 0.499) 100%) !important;
}

.loderBlur{
    background: rgb(245 245 245 / 81%) !important;
}

.activeArrow{
    color: #00a695 !important; 
}

.unActiveArrow{
    color: gray !important;
}

.noDataText{
    font-size: 20px;
    font-weight: 600;
}


.xyz{
    color: #00a695 !important; 
}

